import { Context, useContext } from "react";
import { Currency } from "@looksrare/config";
import { GameContext, GameProviderContext } from "@looksrare/uikit";
import { MoDAssetPair } from "@looksrare/yolo-games-gql-typegen";
import { pointsPresets } from "./gems";

export interface MoDConfig {
  selfTimeoutUntil?: string | null;
  isWalletBlocked: boolean;
  isMuted: boolean;
  enabledAssetPairs: MoDAssetPair[];
  points: {
    enabled: boolean;
    preset: keyof typeof pointsPresets;
    baseEntryPoints: Partial<Record<Currency, number>>;
  };
}

export const useMoDConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<MoDConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};
